/**
 * Project: ngiTracking
 * Created by NGI team 06/02/2017.
 */

angular.module("app.services").factory('notificationSrv', function ($http,$rootScope) {

    var service={};

    service.sendMessage=function(data,success,error){
        $http.post($rootScope.config.API_URI+'/notification/send',data).then(function(response) {
            // this callback will be called asynchronously
            // when the response is available

            if (response && response.data && response.data.success){

                success(response.data);
            }
            else{

                error(data);
            }



        }),function(data) {
            error(data)

        };
    };

    return service;

});

