/**
 * Project: safeToLoad
 * Created by Houcine yousfi 15/12/2017.
 */
var config_dev = {
    APP_NAME: "APRR_NOTIFICATION",
    APP_VERSION: "v0.0.2",
    API_URI: "http://localhost:8000/api/v1.0"

};
var config_aprr_notification = {
    APP_NAME: "APRR_NOTIFICATION",
    APP_VERSION: "v0.0.2",
    API_URI: "https://aprr_notification.ngi.tn/api/v1.0"


};

angular.module("app").constant("CONFIG", config_dev);

angular.module("app").config([
    '$translateProvider', "$provide", "ngDialogProvider", "NotificationProvider",
    function ($translateProvider, $provide, ngDialogProvider, NotificationProvider) {
        $translateProvider.useSanitizeValueStrategy('escapeParameters');
        $translateProvider.useStaticFilesLoader({
            files: [{
                prefix: 'i18n/',
                suffix: '.json'
            }, {
                prefix: 'i18n/server/',
                suffix: '.json'
            }]
        });
        $translateProvider.preferredLanguage('fr');
        $translateProvider.forceAsyncReload(true);

        NotificationProvider.setOptions({
            delay: 10000,
            startTop: 20,
            startRight: 10,
            verticalSpacing: 20,
            horizontalSpacing: 20,
            positionX: 'right',
            positionY: 'bottom'
        });

        ngDialogProvider.setDefaults({
            className: 'ngdialog-theme-default'
        });
    }
]);
