angular
    .module('app')
    .config(routesConfig);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise("/login");

  $stateProvider
        .state('login', {
          url: '/login',
          templateUrl: 'app/pages/login/login.tpl.html',
          controller: 'loginCtrl'
        })
        .state('app', {
          url: '/app',
          templateUrl: 'app/pages/main.tpl.html',
          controller: 'mainCtrl',
          abstract: true
        })
        .state('app.home', {
          url: '/home',
          templateUrl: "app/pages/home/home.tpl.html",
          controller: "homeCtrl"
        })
      .state('app.details', {
          url: '/details',
          templateUrl: "app/pages/home/details.tpl.html",
          controller: "detailsCtrl"
        })

}
