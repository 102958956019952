angular.module('app').controller('homeCtrl', function ($rootScope, $scope, $state,notificationSrv,Notification,$translate,$cookieStore) {
    $scope.notification = {
        data:{
            forceShow :true
        }

    };

    $scope.send = function () {
        notificationSrv.sendMessage($scope.notification,function (res) {
            if (res && res.success){
                $cookieStore.put("notificationSent", {
                    data:{
                        title : $scope.notification.data.title,
                        body : $scope.notification.data.body,
                        forceShow :true
                    }
                });
                Notification.success($translate.instant("NOTIFICATION_SENT"));
                $scope.notification = {
                    data:{
                        forceShow :true
                    }

                };
                $state.go('app.details');
            }else{
                Notification.error($translate.instant("Erreur"));
            }
        }, function (err) {
            Notification.error('Erreur');
        });
    }
    $scope.cancel = function () {
        $scope.notification = {
            data:{
                forceShow :true
            }

        };
    }
});
