angular.module("app.directives").directive('myHeader', function ($rootScope,$state, loginSrv) {
    return{
        templateUrl: "app/commons/directives/header/header.tpl.html",
        scope: {

        },
        link:  function (scope, elem, attr) {


            //Sidebar toggled (false when sidebar opened)
            $rootScope.sidebarToggled = false;

            // Sidebar toggling
            scope.toggleSidebar  = function () {
                $rootScope.sidebarToggled = !$rootScope.sidebarToggled;
            };

            // Sign out user
            scope.logout=function() {
                loginSrv.logout( function(){
                    $rootScope.appData={};
                    $rootScope.maplayers={};
                    $state.go("login", {});
                })
            };


        }
    }
});